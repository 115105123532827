import { __awaiter, __generator, __values } from "tslib";
import { NodeHelper } from '../helpers/NodeHelper';
import { RESTRICTED_ASCENDANT_ATTRIBUTE, TOLGEE_ATTRIBUTE_NAME, } from '../Constants/Global';
var AbstractHandler = /** @class */ (function () {
    function AbstractHandler(properties, textService, elementRegistrar, translationHighlighter) {
        this.properties = properties;
        this.textService = textService;
        this.elementRegistrar = elementRegistrar;
        this.translationHighlighter = translationHighlighter;
    }
    AbstractHandler.initParentElement = function (element) {
        if (element[TOLGEE_ATTRIBUTE_NAME] === undefined) {
            element[TOLGEE_ATTRIBUTE_NAME] = {
                nodes: new Set(),
            };
            element.setAttribute(TOLGEE_ATTRIBUTE_NAME, '');
        }
        return element;
    };
    AbstractHandler.prototype.filterRestricted = function (nodes) {
        var restrictedElements = this.properties.config.restrictedElements;
        return nodes.filter(function (n) {
            var e = NodeHelper.closestElement(n);
            return (restrictedElements.indexOf(e.tagName.toLowerCase()) === -1 &&
                e.closest("[" + RESTRICTED_ASCENDANT_ATTRIBUTE + "=\"true\"]") === null);
        });
    };
    AbstractHandler.prototype.handleNodes = function (nodes) {
        return __awaiter(this, void 0, void 0, function () {
            var nodes_1, nodes_1_1, textNode, tolgeeData, result, text, keys, translatedNode, parentElement, e_1_1;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 5, 6, 7]);
                        nodes_1 = __values(nodes), nodes_1_1 = nodes_1.next();
                        _b.label = 1;
                    case 1:
                        if (!!nodes_1_1.done) return [3 /*break*/, 4];
                        textNode = nodes_1_1.value;
                        if (textNode[TOLGEE_ATTRIBUTE_NAME] === undefined) {
                            textNode[TOLGEE_ATTRIBUTE_NAME] = {};
                        }
                        tolgeeData = textNode[TOLGEE_ATTRIBUTE_NAME];
                        if (!((tolgeeData === null || tolgeeData === void 0 ? void 0 : tolgeeData.locked) !== true)) return [3 /*break*/, 3];
                        this.lockNode(textNode);
                        return [4 /*yield*/, this.textService.replace(textNode.textContent)];
                    case 2:
                        result = _b.sent();
                        if (result) {
                            text = result.text, keys = result.keys;
                            translatedNode = this.translateChildNode(textNode, text, keys);
                            parentElement = this.getParentElement(translatedNode);
                            parentElement._tolgee.nodes.add(translatedNode);
                            this.elementRegistrar.register(parentElement);
                        }
                        this.unlockNode(textNode);
                        _b.label = 3;
                    case 3:
                        nodes_1_1 = nodes_1.next();
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        e_1_1 = _b.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 7];
                    case 6:
                        try {
                            if (nodes_1_1 && !nodes_1_1.done && (_a = nodes_1.return)) _a.call(nodes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AbstractHandler.prototype.translateChildNode = function (node, newValue, keys) {
        node[TOLGEE_ATTRIBUTE_NAME] = {
            oldTextContent: node.textContent,
            keys: keys,
        };
        node.textContent = newValue;
        return node;
    };
    AbstractHandler.prototype.lockNode = function (node) {
        if (node[TOLGEE_ATTRIBUTE_NAME] === undefined) {
            node[TOLGEE_ATTRIBUTE_NAME] = {};
        }
        var tolgeeData = node[TOLGEE_ATTRIBUTE_NAME];
        if ((tolgeeData === null || tolgeeData === void 0 ? void 0 : tolgeeData.locked) !== true) {
            tolgeeData.locked = true;
        }
        return node;
    };
    AbstractHandler.prototype.unlockNode = function (node) {
        if (node[TOLGEE_ATTRIBUTE_NAME]) {
            node[TOLGEE_ATTRIBUTE_NAME].locked = false;
        }
    };
    AbstractHandler.prototype.getParentElement = function (node) {
        var parent = this.getSuitableParent(node);
        return AbstractHandler.initParentElement(parent);
    };
    AbstractHandler.prototype.getSuitableParent = function (node) {
        var domParent = NodeHelper.getParentElement(node);
        if (domParent === undefined) {
            // eslint-disable-next-line no-console
            console.error(node);
            throw new Error('No suitable parent found for node above.');
        }
        if (!this.properties.config.passToParent) {
            return domParent;
        }
        if (Array.isArray(this.properties.config.passToParent)) {
            var tagNameEquals = function (elementTagName) {
                return domParent.tagName.toLowerCase() === elementTagName.toLowerCase();
            };
            if (this.properties.config.passToParent.findIndex(tagNameEquals) === -1) {
                return domParent;
            }
        }
        if (typeof this.properties.config.passToParent === 'function') {
            if (!this.properties.config.passToParent(domParent)) {
                return domParent;
            }
        }
        return this.getSuitableParent(domParent);
    };
    return AbstractHandler;
}());
export { AbstractHandler };
