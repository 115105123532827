import { __generator, __values } from "tslib";
import { TOLGEE_ATTRIBUTE_NAME } from '../Constants/Global';
import { NodeHelper } from '../helpers/NodeHelper';
var ElementRegistrar = /** @class */ (function () {
    function ElementRegistrar(properties, translationHighlighter, eventService) {
        this.properties = properties;
        this.translationHighlighter = translationHighlighter;
        this.eventService = eventService;
        this.registeredElements = new Set();
    }
    ElementRegistrar.prototype.register = function (element) {
        //ignore element with no active nodes
        if (this.getActiveNodes(element).next().value === undefined &&
            !element._tolgee.wrappedWithElementOnlyKey) {
            return;
        }
        if (this.properties.config.mode === 'development' &&
            !this.registeredElements.has(element)) {
            this.translationHighlighter.listen(element);
        }
        this.registeredElements.add(element);
        this.eventService.ELEMENT_REGISTERED.emit(element);
    };
    ElementRegistrar.prototype.refreshAll = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.registeredElements), _c = _b.next(); !_c.done; _c = _b.next()) {
                var element = _c.value;
                this.cleanElementInactiveNodes(element);
                if (element._tolgee.nodes.size === 0 &&
                    !element._tolgee.wrappedWithElementOnlyKey) {
                    this.cleanElement(element);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ElementRegistrar.prototype.cleanAll = function () {
        var e_2, _a;
        try {
            for (var _b = __values(this.registeredElements), _c = _b.next(); !_c.done; _c = _b.next()) {
                var registeredElement = _c.value;
                this.cleanElement(registeredElement);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    ElementRegistrar.prototype.findAllByKey = function (key) {
        var e_3, _a, e_4, _b;
        var result = [];
        try {
            for (var _c = __values(this.registeredElements), _d = _c.next(); !_d.done; _d = _c.next()) {
                var registeredElement = _d.value;
                if (registeredElement._tolgee.wrappedWithElementOnlyKey === key) {
                    result.push(registeredElement);
                    continue;
                }
                try {
                    for (var _e = (e_4 = void 0, __values(registeredElement._tolgee.nodes)), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var node = _f.value;
                        if (node._tolgee.keys.findIndex(function (keyWithParams) { return keyWithParams.key === key; }) > -1) {
                            result.push(registeredElement);
                            break;
                        }
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return result;
    };
    ElementRegistrar.prototype.cleanElementInactiveNodes = function (element) {
        if (this.isElementActive(element)) {
            element._tolgee.nodes = new Set(this.getActiveNodes(element));
            return;
        }
    };
    ElementRegistrar.prototype.cleanElement = function (element) {
        if (typeof element._tolgee.removeAllEventListeners === 'function') {
            element._tolgee.removeAllEventListeners();
        }
        element.removeAttribute(TOLGEE_ATTRIBUTE_NAME);
        delete element._tolgee;
        this.registeredElements.delete(element);
    };
    ElementRegistrar.prototype.getActiveNodes = function (element) {
        var _a, _b, node, e_5_1;
        var e_5, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 5, 6, 7]);
                    _a = __values(element._tolgee.nodes), _b = _a.next();
                    _d.label = 1;
                case 1:
                    if (!!_b.done) return [3 /*break*/, 4];
                    node = _b.value;
                    if (!NodeHelper.nodeContains(this.properties.config.targetElement, node)) return [3 /*break*/, 3];
                    return [4 /*yield*/, node];
                case 2:
                    _d.sent();
                    _d.label = 3;
                case 3:
                    _b = _a.next();
                    return [3 /*break*/, 1];
                case 4: return [3 /*break*/, 7];
                case 5:
                    e_5_1 = _d.sent();
                    e_5 = { error: e_5_1 };
                    return [3 /*break*/, 7];
                case 6:
                    try {
                        if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                    }
                    finally { if (e_5) throw e_5.error; }
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    };
    ElementRegistrar.prototype.isElementActive = function (element) {
        return this.properties.config.targetElement.contains(element);
    };
    return ElementRegistrar;
}());
export { ElementRegistrar };
