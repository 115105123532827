import { __awaiter, __generator } from "tslib";
import { TolgeeConfig } from './TolgeeConfig';
import { NodeHelper } from './helpers/NodeHelper';
import { DependencyStore } from './services/DependencyStore';
var Tolgee = /** @class */ (function () {
    function Tolgee(config) {
        var _this = this;
        this.stop = function () {
            _this.dependencyStore.observer.stopObserving();
            _this.dependencyStore.elementRegistrar.cleanAll();
            NodeHelper.unmarkElementAsTargetElement(_this.properties.config.targetElement);
        };
        this.dependencyStore = new DependencyStore(new TolgeeConfig(config));
    }
    Object.defineProperty(Tolgee.prototype, "properties", {
        get: function () {
            return this.dependencyStore.properties;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "translationService", {
        get: function () {
            return this.dependencyStore.translationService;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "coreService", {
        get: function () {
            return this.dependencyStore.coreService;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "lang", {
        get: function () {
            return this.properties.currentLanguage;
        },
        set: function (value) {
            this.properties.currentLanguage = value;
            this.dependencyStore.eventService
                .LANGUAGE_CHANGED.emit(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "defaultLanguage", {
        get: function () {
            return this.properties.config.defaultLanguage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onLangChange", {
        get: function () {
            return this.dependencyStore.eventService.LANGUAGE_CHANGED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onTranslationChange", {
        get: function () {
            return this.dependencyStore.eventService.TRANSLATION_CHANGED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "onLangLoaded", {
        get: function () {
            return this.dependencyStore.eventService.LANGUAGE_LOADED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tolgee.prototype, "initialLoading", {
        /**
         * True if loading is needed to wait for Tolgee init.
         * When translation data are provided statically (using import
         * as staticData config property) then there is no need for translation
         * fetching so initial loading is not needed at all.
         */
        get: function () {
            var _a, _b, _c, _d;
            if (((_b = (_a = this.properties) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.mode) !== 'production') {
                return true;
            }
            var currentLang = this.properties.currentLanguage;
            var fallbackLang = this.properties.config.fallbackLanguage;
            var fallbackPreloading = this.properties.config.preloadFallback;
            var isStaticDataProvided = function (data) {
                return data !== undefined && typeof data !== 'function';
            };
            return (!isStaticDataProvided((_c = this.properties.config.staticData) === null || _c === void 0 ? void 0 : _c[currentLang]) ||
                (!!fallbackPreloading &&
                    !isStaticDataProvided((_d = this.properties.config.staticData) === null || _d === void 0 ? void 0 : _d[fallbackLang])));
        },
        enumerable: false,
        configurable: true
    });
    Tolgee.prototype.run = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.properties.config.mode === 'development')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadScopes()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.translationService.loadTranslations()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.dependencyStore.pluginManager.run()];
                    case 4:
                        _a.sent();
                        if (!this.properties.config.preloadFallback) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.translationService.loadTranslations(this.properties.config.fallbackLanguage)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [4 /*yield*/, this.refresh()];
                    case 7:
                        _a.sent();
                        if (this.properties.config.watch) {
                            this.dependencyStore.observer.observe();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Tolgee.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dependencyStore.coreHandler.handleSubtree(this.properties.config.targetElement)];
            });
        });
    };
    Tolgee.prototype.translate = function (keyOrProps, params, noWrap, defaultValue) {
        if (params === void 0) { params = {}; }
        if (noWrap === void 0) { noWrap = false; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        return __awaiter(this, void 0, void 0, function () {
            var key, orEmpty, props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = typeof keyOrProps === 'string' ? keyOrProps : keyOrProps.key;
                        orEmpty = undefined;
                        if (typeof keyOrProps === 'object') {
                            props = keyOrProps;
                            // if values are not provided in props object, get them from function
                            // params defaults
                            params = props.params !== undefined ? props.params : params;
                            noWrap = props.noWrap !== undefined ? props.noWrap : noWrap;
                            defaultValue =
                                props.defaultValue !== undefined ? props.defaultValue : defaultValue;
                            orEmpty = props.orEmpty;
                        }
                        if (!(this.properties.config.mode === 'development' && !noWrap)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.loadScopes()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.translationService.loadTranslations()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, this.dependencyStore.textService.wrap(key, params, defaultValue)];
                    case 3: return [2 /*return*/, this.dependencyStore.textService.translate(key, params, undefined, orEmpty, defaultValue)];
                }
            });
        });
    };
    Tolgee.prototype.instant = function (keyOrProps, params, noWrap, orEmpty, defaultValue) {
        if (params === void 0) { params = {}; }
        if (noWrap === void 0) { noWrap = false; }
        var key = typeof keyOrProps === 'string' ? keyOrProps : keyOrProps.key;
        if (typeof keyOrProps === 'object') {
            var props = keyOrProps;
            // if values are not provided in props object, get them from function
            // params defaults
            params = props.params !== undefined ? props.params : params;
            noWrap = props.noWrap !== undefined ? props.noWrap : noWrap;
            defaultValue =
                props.defaultValue !== undefined ? props.defaultValue : defaultValue;
            orEmpty = props.orEmpty !== undefined ? props.orEmpty : orEmpty;
        }
        if (this.properties.config.mode === 'development' && !noWrap) {
            return this.dependencyStore.textService.wrap(key, params, defaultValue);
        }
        return this.dependencyStore.textService.instant(key, params, undefined, orEmpty, defaultValue);
    };
    Tolgee.prototype.loadScopes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.properties.scopes === undefined)) return [3 /*break*/, 2];
                        _a = this.properties;
                        return [4 /*yield*/, this.dependencyStore.coreService.getScopes()];
                    case 1:
                        _a.scopes =
                            _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return Tolgee;
}());
export { Tolgee };
export default Tolgee;
