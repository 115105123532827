import { __awaiter, __extends, __generator } from "tslib";
import { NodeHelper } from '../helpers/NodeHelper';
import { AbstractHandler } from './AbstractHandler';
var TextHandler = /** @class */ (function (_super) {
    __extends(TextHandler, _super);
    function TextHandler(properties, translationHighlighter, textService, nodeRegistrar) {
        var _this = _super.call(this, properties, textService, nodeRegistrar, translationHighlighter) || this;
        _this.properties = properties;
        _this.translationHighlighter = translationHighlighter;
        _this.textService = textService;
        _this.nodeRegistrar = nodeRegistrar;
        return _this;
    }
    TextHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var inputPrefix, inputSuffix, xPath, nodes, filtered;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        inputPrefix = this.properties.config.inputPrefix;
                        inputSuffix = this.properties.config.inputSuffix;
                        xPath = "./descendant-or-self::text()[contains(., '" + inputPrefix + "') and contains(., '" + inputSuffix + "')]";
                        nodes = NodeHelper.evaluate(xPath, node);
                        filtered = this.filterRestricted(nodes);
                        return [4 /*yield*/, this.handleNodes(filtered)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TextHandler;
}(AbstractHandler));
export { TextHandler };
