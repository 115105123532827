import { __awaiter, __generator, __read, __spreadArray, __values } from "tslib";
var TranslationHighlighter = /** @class */ (function () {
    function TranslationHighlighter(dependencies) {
        var _this = this;
        this.dependencies = dependencies;
        this.translationEdit = function (e, element) { return __awaiter(_this, void 0, void 0, function () {
            var renderer, key;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getRenderer()];
                    case 1:
                        renderer = _a.sent();
                        if (!(typeof renderer === 'object')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getKeyAndDefault(e, element)];
                    case 2:
                        key = _a.sent();
                        if (key) {
                            renderer.renderViewer(key.key, key.defaultValue);
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }
    TranslationHighlighter.getKeyOptions = function (node) {
        var nodes = Array.from(node._tolgee.nodes);
        return nodes.reduce(function (acc, curr) { return __spreadArray(__spreadArray([], __read(acc), false), __read(curr._tolgee.keys.map(function (k) { return ({
            key: k.key,
            defaultValue: k.defaultValue,
        }); })), false); }, []);
    };
    TranslationHighlighter.prototype.listen = function (element) {
        var _this = this;
        this.dependencies.highlightFunctionInitializer.initFunctions(element);
        this.dependencies.mouseEventHandler.handle(element, function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, this.translationEdit(e, element)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        }); }); });
    };
    TranslationHighlighter.prototype.getRenderer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var possibleProviders, possibleProviders_1, possibleProviders_1_1, possibleProvider, constructorProvider, constructor, e_1, constructor, e_2_1;
            var e_2, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this._renderer === undefined)) return [3 /*break*/, 11];
                        possibleProviders = [
                            this.dependencies.properties.config.ui,
                            window['@tolgee/ui'],
                        ];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 8, 9, 10]);
                        possibleProviders_1 = __values(possibleProviders), possibleProviders_1_1 = possibleProviders_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!possibleProviders_1_1.done) return [3 /*break*/, 7];
                        possibleProvider = possibleProviders_1_1.value;
                        if (!(typeof possibleProvider === 'function')) return [3 /*break*/, 6];
                        _b.label = 3;
                    case 3:
                        _b.trys.push([3, 5, , 6]);
                        constructorProvider = possibleProvider;
                        return [4 /*yield*/, constructorProvider()];
                    case 4:
                        constructor = _b.sent();
                        this._renderer = new constructor(this.dependencies);
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        constructor = possibleProvider;
                        this._renderer = new constructor(this.dependencies);
                        return [3 /*break*/, 6];
                    case 6:
                        possibleProviders_1_1 = possibleProviders_1.next();
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_2_1 = _b.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (possibleProviders_1_1 && !possibleProviders_1_1.done && (_a = possibleProviders_1.return)) _a.call(possibleProviders_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        if (this._renderer === undefined) {
                            // eslint-disable-next-line no-console
                            console.warn('Tolgee UI is not provided. To translate interactively provide tolgee ui constructor to "ui" configuration property. ' +
                                'To disable highlighting use production mode.');
                        }
                        _b.label = 11;
                    case 11: return [2 /*return*/, this._renderer];
                }
            });
        });
    };
    TranslationHighlighter.prototype.getKeyAndDefault = function (mouseEvent, element) {
        return __awaiter(this, void 0, void 0, function () {
            var keysWithDefaults, keySet, renderer, selectedKey_1, found;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (element._tolgee.wrappedWithElementOnlyKey) {
                            return [2 /*return*/, {
                                    key: element._tolgee.wrappedWithElementOnlyKey,
                                    defaultValue: element._tolgee.wrappedWithElementOnlyDefaultHtml,
                                }];
                        }
                        keysWithDefaults = TranslationHighlighter.getKeyOptions(element);
                        keySet = new Set(keysWithDefaults.map(function (keyWithDefault) { return keyWithDefault.key; }));
                        if (!(keySet.size > 1)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getRenderer()];
                    case 1:
                        renderer = _a.sent();
                        return [4 /*yield*/, renderer.getKey({
                                keys: keySet,
                                openEvent: mouseEvent,
                            })];
                    case 2:
                        selectedKey_1 = _a.sent();
                        found = keysWithDefaults.find(function (kwd) { return kwd.key === selectedKey_1; });
                        if (found) {
                            return [2 /*return*/, found];
                        }
                        _a.label = 3;
                    case 3:
                        if (keySet.size === 1) {
                            return [2 /*return*/, keysWithDefaults[0]];
                        }
                        // eslint-disable-next-line no-console
                        console.error('No key to translate. This seems like a bug in tolgee.');
                        return [2 /*return*/];
                }
            });
        });
    };
    return TranslationHighlighter;
}());
export { TranslationHighlighter };
