import { __generator, __read, __spreadArray } from "tslib";
import { TOLGEE_TARGET_ATTRIBUTE } from '../Constants/Global';
var NodeHelper = /** @class */ (function () {
    function NodeHelper() {
    }
    NodeHelper.evaluateGenerator = function (expression, targetNode) {
        var node, evaluated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    evaluated = document.evaluate(expression, targetNode, undefined, XPathResult.ANY_TYPE);
                    _a.label = 1;
                case 1:
                    if (!((node = evaluated.iterateNext()) !== null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, node];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 1];
                case 3: return [2 /*return*/];
            }
        });
    };
    NodeHelper.evaluate = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return Array.from(this.evaluateGenerator.apply(this, __spreadArray([], __read(args), false)));
    };
    NodeHelper.evaluateToSingle = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var arr = this.evaluate.apply(this, __spreadArray([], __read(args), false));
        if (arr.length === 1) {
            return arr[0];
        }
        if (arr.length < 1) {
            throw new Error('No element found');
        }
        throw new Error('Multiple elements found');
    };
    NodeHelper.closestElement = function (node) {
        if (node instanceof Text) {
            return node.parentElement;
        }
        return node;
    };
    NodeHelper.getParentElement = function (node) {
        if (node.parentElement) {
            return node.parentElement;
        }
        if (node.ownerElement) {
            return node.ownerElement;
        }
    };
    NodeHelper.isElementTargetElement = function (element) {
        return element.hasAttribute(TOLGEE_TARGET_ATTRIBUTE);
    };
    NodeHelper.markElementAsTargetElement = function (element) {
        element.setAttribute(TOLGEE_TARGET_ATTRIBUTE, '');
    };
    NodeHelper.unmarkElementAsTargetElement = function (element) {
        element.removeAttribute(TOLGEE_TARGET_ATTRIBUTE);
    };
    NodeHelper.nodeContains = function (descendant, node) {
        if (descendant.contains(node)) {
            return true;
        }
        if (node instanceof Attr) {
            var ownerContainsAttr = Object.values(node.ownerElement.attributes).indexOf(node) > -1;
            if (descendant.contains(node.ownerElement) && ownerContainsAttr) {
                return true;
            }
        }
        return false;
    };
    return NodeHelper;
}());
export { NodeHelper };
