import { __values } from "tslib";
import { ModifierKey } from '../Constants/ModifierKey';
import { EventEmitterImpl } from '../services/EventEmitter';
var MouseEventHandler = /** @class */ (function () {
    function MouseEventHandler(properties) {
        this.properties = properties;
        this.keysDown = new Set();
        this.mouseOn = new Set();
        this.mouseOnChanged = new EventEmitterImpl();
        this.keysChanged = new EventEmitterImpl();
        if (typeof window !== 'undefined') {
            this.initKeyListener();
            return;
        }
    }
    MouseEventHandler.prototype.handle = function (element, onclick) {
        var _this = this;
        if (element._tolgee.listeningForHighlighting) {
            // eslint-disable-next-line no-console
            console.error('Element is already listening mouse events! This is probably bug in tolgee');
            return;
        }
        element._tolgee.listeningForHighlighting = true;
        this.initEventListeners(element, onclick);
        this.mouseOnChanged.subscribe(function () {
            if (_this.highlighted !== _this.getMouseOn()) {
                _this.onConditionsChanged();
            }
        });
        this.keysChanged.subscribe(function () {
            _this.onConditionsChanged();
        });
    };
    MouseEventHandler.prototype.initEventListeners = function (element, onclick) {
        var _this = this;
        var onMouseOver = function () { return _this.onMouseOver(element); };
        var onMouseOut = function () { return _this.onMouseOut(element); };
        var onClick = function (e) {
            if (_this.areKeysDown()) {
                e.stopPropagation();
                e.preventDefault();
                onclick(e);
            }
        };
        element.addEventListener('mouseover', onMouseOver);
        element.addEventListener('click', onClick, { capture: true });
        var onMouseDownOrUp = function (e) {
            if (_this.areKeysDown()) {
                e.stopPropagation();
                e.preventDefault();
            }
        };
        element.addEventListener('mousedown', onMouseDownOrUp);
        element.addEventListener('mouseup', onMouseDownOrUp);
        element.addEventListener('mouseout', onMouseOut);
        element._tolgee.removeAllEventListeners = function () {
            element.removeEventListener('mousedown', onMouseDownOrUp);
            element.removeEventListener('mouseup', onMouseDownOrUp);
            element.removeEventListener('mouseover', onMouseOver);
            element.removeEventListener('click', onClick, { capture: true });
            element.removeEventListener('mouseout', onMouseOut);
        };
    };
    MouseEventHandler.prototype.onConditionsChanged = function () {
        this.unhighlight();
        if (this.areKeysDown() && this.getMouseOn()) {
            this.highlight();
        }
    };
    MouseEventHandler.prototype.highlight = function () {
        this.getMouseOn()._tolgee.highlight();
        this.highlighted = this.getMouseOn();
    };
    MouseEventHandler.prototype.unhighlight = function () {
        if (this.highlighted) {
            this.highlighted._tolgee.unhighlight();
            this.highlighted = null;
        }
    };
    MouseEventHandler.prototype.onMouseOut = function (element) {
        this.mouseOn.delete(element);
        this.mouseOnChanged.emit(this.getMouseOn());
    };
    MouseEventHandler.prototype.onMouseOver = function (element) {
        this.filterMouseOn();
        this.mouseOn.delete(element); //to get in to last place
        this.mouseOn.add(element);
        this.mouseOnChanged.emit(this.getMouseOn());
    };
    MouseEventHandler.prototype.getMouseOn = function () {
        var mouseOnArray = Array.from(this.mouseOn);
        return mouseOnArray.length ? mouseOnArray[0] : undefined;
    };
    MouseEventHandler.prototype.initKeyListener = function () {
        var _this = this;
        window.addEventListener('blur', function () {
            _this.keysDown = new Set();
            _this.keysChanged.emit(_this.areKeysDown());
        });
        window.addEventListener('keydown', function (e) {
            var modifierKey = ModifierKey[e.key];
            if (modifierKey !== undefined) {
                _this.keysDown.add(modifierKey);
                _this.keysChanged.emit(_this.areKeysDown());
            }
        });
        window.addEventListener('keyup', function (e) {
            _this.keysDown.delete(ModifierKey[e.key]);
            _this.keysChanged.emit(_this.areKeysDown());
        });
    };
    MouseEventHandler.prototype.filterMouseOn = function () {
        var _this = this;
        this.mouseOn.forEach(function (el) {
            if (!document.contains(el)) {
                _this.mouseOn.delete(el);
            }
        });
    };
    MouseEventHandler.prototype.areKeysDown = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.properties.config.highlightKeys), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                if (!this.keysDown.has(key)) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return true;
    };
    return MouseEventHandler;
}());
export { MouseEventHandler };
