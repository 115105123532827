import { __awaiter, __extends, __generator } from "tslib";
import { NodeHelper } from '../helpers/NodeHelper';
import { AbstractHandler } from './AbstractHandler';
import { TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE } from '../Constants/Global';
var WrappedHandler = /** @class */ (function (_super) {
    __extends(WrappedHandler, _super);
    function WrappedHandler(properties, translationHighlighter, textService, elementRegistrar) {
        var _this = _super.call(this, properties, textService, elementRegistrar, translationHighlighter) || this;
        _this.properties = properties;
        _this.translationHighlighter = translationHighlighter;
        _this.textService = textService;
        _this.elementRegistrar = elementRegistrar;
        return _this;
    }
    WrappedHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var xPath, nodes, filtered;
            var _this = this;
            return __generator(this, function (_a) {
                xPath = "./descendant-or-self::*[@" + TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE + "]";
                nodes = NodeHelper.evaluate(xPath, node);
                filtered = this.filterRestricted(nodes);
                filtered.forEach(function (element) {
                    var elementWithMeta = AbstractHandler.initParentElement(element);
                    elementWithMeta._tolgee.wrappedWithElementOnlyKey = element.getAttribute(TOLGEE_WRAPPED_ONLY_DATA_ATTRIBUTE);
                    elementWithMeta._tolgee.wrappedWithElementOnlyDefaultHtml =
                        element.innerHTML;
                    _this.elementRegistrar.register(elementWithMeta);
                });
                return [2 /*return*/];
            });
        });
    };
    return WrappedHandler;
}(AbstractHandler));
export { WrappedHandler };
