import { __awaiter, __generator, __values } from "tslib";
import { NodeHelper } from '../helpers/NodeHelper';
var CoreHandler = /** @class */ (function () {
    function CoreHandler(service, textHandler, eventService, properties, attributeHandler, textService, wrappedHandler) {
        this.service = service;
        this.textHandler = textHandler;
        this.eventService = eventService;
        this.properties = properties;
        this.attributeHandler = attributeHandler;
        this.textService = textService;
        this.wrappedHandler = wrappedHandler;
        if (typeof window !== 'undefined') {
            eventService.LANGUAGE_CHANGED.subscribe(this.refresh.bind(this));
            eventService.TRANSLATION_CHANGED.subscribe(this.refresh.bind(this));
        }
    }
    CoreHandler.prototype.handleSubtree = function (target) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.attributeHandler.handle(target)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.textHandler.handle(target)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.wrappedHandler.handle(target)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CoreHandler.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var nodes, nodes_1, nodes_1_1, node, _a, _b, textNode, result, e_1_1, e_2_1;
            var e_2, _c, e_1, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        nodes = NodeHelper.evaluate("//*[@_tolgee]", this.properties.config.targetElement);
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 12, 13, 14]);
                        nodes_1 = __values(nodes), nodes_1_1 = nodes_1.next();
                        _e.label = 2;
                    case 2:
                        if (!!nodes_1_1.done) return [3 /*break*/, 11];
                        node = nodes_1_1.value;
                        _e.label = 3;
                    case 3:
                        _e.trys.push([3, 8, 9, 10]);
                        _a = (e_1 = void 0, __values(node._tolgee.nodes)), _b = _a.next();
                        _e.label = 4;
                    case 4:
                        if (!!_b.done) return [3 /*break*/, 7];
                        textNode = _b.value;
                        return [4 /*yield*/, this.textService.replace(textNode._tolgee.oldTextContent)];
                    case 5:
                        result = _e.sent();
                        if (result) {
                            textNode.textContent = result.text;
                        }
                        _e.label = 6;
                    case 6:
                        _b = _a.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        nodes_1_1 = nodes_1.next();
                        return [3 /*break*/, 2];
                    case 11: return [3 /*break*/, 14];
                    case 12:
                        e_2_1 = _e.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 14];
                    case 13:
                        try {
                            if (nodes_1_1 && !nodes_1_1.done && (_c = nodes_1.return)) _c.call(nodes_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    return CoreHandler;
}());
export { CoreHandler };
