import { __awaiter, __generator, __read, __values } from "tslib";
import { TextHelper } from '../helpers/TextHelper';
import IntlMessageFormat from 'intl-messageformat';
var TextService = /** @class */ (function () {
    function TextService(properties, translationService) {
        var _this = this;
        this.properties = properties;
        this.translationService = translationService;
        this.format = function (translation, params) {
            try {
                //try to format the text
                return new IntlMessageFormat(translation, _this.properties.currentLanguage).format(params);
            }
            catch (e) {
                // if string cannot be formatted, throw error
                // eslint-disable-next-line no-console
                console.error(e);
            }
        };
        this.escapeForRegExp = function (string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        };
        this.escapeParam = function (param) {
            if (typeof param === 'string') {
                return param.replace(/[,:\\]/gs, '\\$&');
            }
            if (typeof param === 'number' || typeof param === 'bigint') {
                return param.toString();
            }
            // eslint-disable-next-line no-console
            console.warn(param);
            // eslint-disable-next-line no-console
            console.warn('Unsupported value type of above param. Consider converting to string.');
            return param;
        };
    }
    Object.defineProperty(TextService.prototype, "rawUnWrapRegex", {
        get: function () {
            var escapedPrefix = this.escapeForRegExp(this.properties.config.inputPrefix);
            var escapedSuffix = this.escapeForRegExp(this.properties.config.inputSuffix);
            return "(\\\\?)(" + escapedPrefix + "(.*?)" + escapedSuffix + ")";
        },
        enumerable: false,
        configurable: true
    });
    TextService.parseUnwrapped = function (unwrappedString) {
        var e_1, _a;
        var escaped = false;
        var actual = '';
        var paramName = '';
        var readingState = 'KEY';
        var result = {
            key: '',
            params: {},
            defaultValue: undefined,
        };
        try {
            for (var unwrappedString_1 = __values(unwrappedString), unwrappedString_1_1 = unwrappedString_1.next(); !unwrappedString_1_1.done; unwrappedString_1_1 = unwrappedString_1.next()) {
                var char = unwrappedString_1_1.value;
                if (char === '\\' && !escaped) {
                    escaped = true;
                    continue;
                }
                if (escaped) {
                    escaped = false;
                    actual += char;
                    continue;
                }
                if (readingState === 'KEY' && char === ',') {
                    readingState = 'DEFAULT_VALUE';
                    result.key = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'KEY' && char === ':') {
                    readingState = 'PARAM_NAME';
                    result.key = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'DEFAULT_VALUE' && char === ':') {
                    readingState = 'PARAM_NAME';
                    result.defaultValue = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'PARAM_NAME' && char === ':') {
                    readingState = 'PARAM_VALUE';
                    paramName = actual;
                    actual = '';
                    continue;
                }
                if (readingState === 'PARAM_VALUE' && char === ',') {
                    readingState = 'PARAM_NAME';
                    result.params[paramName] = actual;
                    actual = '';
                    continue;
                }
                actual += char;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (unwrappedString_1_1 && !unwrappedString_1_1.done && (_a = unwrappedString_1.return)) _a.call(unwrappedString_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (readingState === 'KEY') {
            result.key = actual;
        }
        if (readingState === 'DEFAULT_VALUE') {
            result.defaultValue = actual;
        }
        if (readingState === 'PARAM_VALUE') {
            result.params[paramName] = actual;
        }
        return result;
    };
    TextService.prototype.translate = function (key, params, lang, orEmpty, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        return __awaiter(this, void 0, void 0, function () {
            var result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.format;
                        return [4 /*yield*/, this.translationService.getTranslation(key, lang, orEmpty, defaultValue)];
                    case 1:
                        result = _a.apply(this, [_b.sent(), params]);
                        if (result !== undefined) {
                            return [2 /*return*/, result];
                        }
                        return [2 /*return*/, key];
                }
            });
        });
    };
    TextService.prototype.instant = function (key, params, lang, orEmpty, defaultValue) {
        if (lang === void 0) { lang = this.properties.currentLanguage; }
        var result = this.format(this.translationService.getFromCacheOrFallback(key, lang, orEmpty, defaultValue), params);
        if (result !== undefined) {
            return result;
        }
        return key;
    };
    TextService.prototype.replace = function (text) {
        return __awaiter(this, void 0, void 0, function () {
            var matchRegexp, keysAndParams, matched, match, start, result, pre, _a, fullMatch, _, wrapped, unwrapped, index, input, translated;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        matchRegexp = new RegExp(this.rawUnWrapRegex, 'gs');
                        keysAndParams = [];
                        matched = false;
                        start = 0;
                        result = '';
                        _b.label = 1;
                    case 1:
                        if (!((match = matchRegexp.exec(text)) !== null)) return [3 /*break*/, 3];
                        pre = match[1];
                        _a = __read(match, 4), fullMatch = _a[0], _ = _a[1], wrapped = _a[2], unwrapped = _a[3];
                        index = match.index, input = match.input;
                        result += input.substr(start, index - start);
                        start = index + fullMatch.length;
                        if (pre === '\\') {
                            if (!TextHelper.isCharEscaped(index, text)) {
                                result += wrapped;
                                return [3 /*break*/, 1];
                            }
                            pre = '';
                        }
                        return [4 /*yield*/, this.getTranslatedWithMetadata(unwrapped)];
                    case 2:
                        translated = _b.sent();
                        keysAndParams.push({
                            key: translated.key,
                            params: translated.params,
                            defaultValue: translated.defaultValue,
                        });
                        matched = true;
                        result += pre + translated.translated;
                        return [3 /*break*/, 1];
                    case 3:
                        result += text.substring(start);
                        if (matched) {
                            return [2 /*return*/, { text: result, keys: keysAndParams }];
                        }
                        return [2 /*return*/, undefined];
                }
            });
        });
    };
    TextService.prototype.wrap = function (key, params, defaultValue) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (defaultValue === void 0) { defaultValue = undefined; }
        var paramString = Object.entries(params)
            .map(function (_a) {
            var _b = __read(_a, 2), name = _b[0], value = _b[1];
            return _this.escapeParam(name) + ":" + _this.escapeParam(value);
        })
            .join(',');
        paramString = paramString.length ? ":" + paramString : '';
        var defaultString = defaultValue !== undefined ? "," + this.escapeParam(defaultValue) : '';
        return "" + this.properties.config.inputPrefix + this.escapeParam(key) + defaultString + paramString + this.properties.config.inputSuffix;
    };
    TextService.prototype.getTranslatedWithMetadata = function (text) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, key, params, defaultValue, translated;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = TextService.parseUnwrapped(text), key = _a.key, params = _a.params, defaultValue = _a.defaultValue;
                        return [4 /*yield*/, this.translate(key, params, undefined, false, defaultValue)];
                    case 1:
                        translated = _b.sent();
                        return [2 /*return*/, { translated: translated, key: key, params: params, defaultValue: defaultValue }];
                }
            });
        });
    };
    return TextService;
}());
export { TextService };
