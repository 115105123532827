import { __awaiter, __extends, __generator, __read, __values } from "tslib";
import { AbstractHandler } from './AbstractHandler';
import { NodeHelper } from '../helpers/NodeHelper';
var AttributeHandler = /** @class */ (function (_super) {
    __extends(AttributeHandler, _super);
    function AttributeHandler(properties, textService, elementRegistrar, translationHighlighter) {
        var _this = _super.call(this, properties, textService, elementRegistrar, translationHighlighter) || this;
        _this.properties = properties;
        _this.textService = textService;
        _this.elementRegistrar = elementRegistrar;
        _this.translationHighlighter = translationHighlighter;
        return _this;
    }
    AttributeHandler.prototype.handle = function (node) {
        return __awaiter(this, void 0, void 0, function () {
            var inputPrefix, inputSuffix, _a, _b, _c, tag, attributes, attributes_1, attributes_1_1, attribute, expression, nodes, e_1_1, e_2_1;
            var e_2, _d, e_1, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        inputPrefix = this.properties.config.inputPrefix;
                        inputSuffix = this.properties.config.inputSuffix;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 12, 13, 14]);
                        _a = __values(Object.entries(this.properties.config.tagAttributes)), _b = _a.next();
                        _f.label = 2;
                    case 2:
                        if (!!_b.done) return [3 /*break*/, 11];
                        _c = __read(_b.value, 2), tag = _c[0], attributes = _c[1];
                        _f.label = 3;
                    case 3:
                        _f.trys.push([3, 8, 9, 10]);
                        attributes_1 = (e_1 = void 0, __values(attributes)), attributes_1_1 = attributes_1.next();
                        _f.label = 4;
                    case 4:
                        if (!!attributes_1_1.done) return [3 /*break*/, 7];
                        attribute = attributes_1_1.value;
                        expression = "descendant-or-self::" + tag + "/@" + attribute + "[contains(., '" + inputPrefix + "') and contains(., '" + inputSuffix + "')]";
                        nodes = NodeHelper.evaluate(expression, node);
                        return [4 /*yield*/, this.handleNodes(nodes)];
                    case 5:
                        _f.sent();
                        _f.label = 6;
                    case 6:
                        attributes_1_1 = attributes_1.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_1_1 = _f.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (attributes_1_1 && !attributes_1_1.done && (_e = attributes_1.return)) _e.call(attributes_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        _b = _a.next();
                        return [3 /*break*/, 2];
                    case 11: return [3 /*break*/, 14];
                    case 12:
                        e_2_1 = _f.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 14];
                    case 13:
                        try {
                            if (_b && !_b.done && (_d = _a.return)) _d.call(_a);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    return AttributeHandler;
}(AbstractHandler));
export { AttributeHandler };
