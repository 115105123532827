import { Properties } from '../Properties';
import { EventService } from './EventService';
import { ApiHttpService } from './ApiHttpService';
import { TranslationService } from './TranslationService';
import { TextService } from './TextService';
import { MouseEventHandler } from '../highlighter/MouseEventHandler';
import { TranslationHighlighter } from '../highlighter/TranslationHighlighter';
import { ElementRegistrar } from './ElementRegistrar';
import { TextHandler } from '../handlers/TextHandler';
import { AttributeHandler } from '../handlers/AttributeHandler';
import { CoreHandler } from '../handlers/CoreHandler';
import { Observer } from '../Observer';
import { CoreService } from './CoreService';
import { TolgeeConfig } from '../TolgeeConfig';
import { WrappedHandler } from '../handlers/WrappedHandler';
import { PluginManager } from '../toolsManager/PluginManager';
import { Messages } from '../toolsManager/Messages';
import { HighlightFunctionsInitializer } from '../highlighter/HighlightFunctionsInitializer';
import { ScreenshotService } from './ScreenshotService';
var DependencyStore = /** @class */ (function () {
    function DependencyStore(config) {
        if (config === void 0) { config = new TolgeeConfig(); }
        this.properties = new Properties();
        this.eventService = new EventService();
        this.apiHttpService = new ApiHttpService(this.properties);
        this.mouseEventHandler = new MouseEventHandler(this.properties);
        this.coreService = new CoreService(this.properties, this.apiHttpService);
        this.screenshotService = new ScreenshotService(this.coreService, this.apiHttpService);
        this.translationService = new TranslationService(this.properties, this.coreService, this.apiHttpService, this.eventService);
        this.textService = new TextService(this.properties, this.translationService);
        this.highlightFunctionInitializer = new HighlightFunctionsInitializer(this.properties);
        this.translationHighlighter = new TranslationHighlighter(this);
        this.elementRegistrar = new ElementRegistrar(this.properties, this.translationHighlighter, this.eventService);
        this.textHandler = new TextHandler(this.properties, this.translationHighlighter, this.textService, this.elementRegistrar);
        this.attributeHandler = new AttributeHandler(this.properties, this.textService, this.elementRegistrar, this.translationHighlighter);
        this.wrappedHandler = new WrappedHandler(this.properties, this.translationHighlighter, this.textService, this.elementRegistrar);
        this.coreHandler = new CoreHandler(this.coreService, this.textHandler, this.eventService, this.properties, this.attributeHandler, this.textService, this.wrappedHandler);
        this.observer = new Observer(this.properties, this.coreHandler, this.textHandler, this.attributeHandler, this.elementRegistrar);
        this.messages = new Messages();
        this.pluginManager = new PluginManager(this.messages, this.properties, this.eventService, this.elementRegistrar, this.translationService);
        this.properties.config = config;
        this.translationService.initStatic();
        this.translationHighlighter.pluginManager = this.pluginManager;
    }
    return DependencyStore;
}());
export { DependencyStore };
